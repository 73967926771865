import $ from 'jquery';
import Backbone from 'backbone';
import 'bootstrap';
import 'admin-lte';

import 'css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'ionicons/dist/css/ionicons.min.css';
import 'admin-lte/dist/css/AdminLTE.min.css';
import 'css/skin-idealayer-light.css';

import appPageTemplate from 'templates/app-page.handlebars';

const AppView = Backbone.View.extend({
  tagName: 'div',
  className: 'wrapper',
  template: appPageTemplate,
  initialize() { },
  render() {
    this.$el.html(this.template({ }));
  },
});

export default AppView;