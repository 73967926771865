import Backbone from 'backbone';

const Router = Backbone.Router.extend({

  routes: {
    '': 'root',
    projects: 'project',
    'projects/:projectId': 'project',
    'projects/:projectId/content': 'content',
    'projects/:projectId/content/*path': 'content',
    'projects/:projectId/datamodel': 'dataModel',
    'projects/:projectId/datamodel/*path': 'dataModel',
  },

  initialize(options) {
    this.app = options.app;
    this.currentPath = '';
  },

  root() {
    Backbone.history.navigate('/projects', { trigger: true, replace: false });
  },

  project(projectId) {
    const { projects: projectsData } = this.app.clientData;
    if (projectsData.length === 0) {
      this.app.changeToNoProjectsPage();
      return;
    }
    
    // redirect to default project if the user has access to any
    if (projectId === null) {
      Backbone.history.navigate(`/projects/${this.app.getDefaultProject().id}/content`, { trigger: true, replace: false });
      return;
    }

    // redirect to content page for this project
    Backbone.history.navigate(`/projects/${projectId}/content`, { trigger: true, replace: false });
  },

  content(projectId, contentPath = '') {
    this.app.changeToContentPage(projectId, contentPath); //async
  },

  dataModel(projectId, _dataModelPath = '') {
    this.app.changeToDataModelPage(projectId); //async
  },

});

export default Router;
