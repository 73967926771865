var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href target=\"_blank\" download=\""
    + alias2(__default(require("../../helpers/lastElementOfURLMinusUUID.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"lastElementOfURLMinusUUID","hash":{},"data":data,"loc":{"start":{"line":3,"column":34},"end":{"line":3,"column":69}}}))
    + "\"><span class='preview-nav btn' style='font-size: 1.25rem; color: #3c8dbc; border-color: #3c8dbc;'>\n  "
    + alias2(__default(require("../../helpers/lastElementOfURLMinusUUID.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"lastElementOfURLMinusUUID","hash":{},"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":4,"column":37}}}))
    + " <i class=\"fa fa-fw fa-download\"></i>\n</span></a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "<div class=\"form-control-static adjustpadding\">No File</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":8,"column":7}}})) != null ? stack1 : "");
},"useData":true});