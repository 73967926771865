
export default async function fetchJson(path, expectedStatuses = [200]) {
  const response = await fetch(path, {
    credentials: 'same-origin',
    redirect: 'error',
  });

  if (!expectedStatuses.includes(response.status)) {
    throw new Error(`Looks like there was a problem. Status Code: ${response.status}`);
    // `Server error when fetching JSON from ${path }. Error: ${JSON.stringify(data, null, 2)}`
  }
  
  if (response.headers.get('Content-Type') !== 'application/json; charset=utf-8') {
    throw new Error('Expected application/json Content-Type header');
  }

  return response.json();
  // console.error(`There was an error while loading '${path}'. Perhaps check your json syntax.`);
}
