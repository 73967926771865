import uuid from 'uuid';
import $ from 'jquery';

export default class S3UploadHandler {
  constructor(uploadDataStoreInfo, projectId) {
    this.signerUrl = `api/projects/${projectId}${uploadDataStoreInfo.signURL}`;
    this.projectId = projectId;
  }

  getDownloadURL(contentSetFilePath) {
    return `api/projects/${this.projectId}/download/${contentSetFilePath}`;
  }

  doUpload(file, onComplete, onProgress) {
    const xhr = new XMLHttpRequest();

    const fileUUID = uuid(); // Temporary uuid that is only used to prevent clashes in the upload bucket
    const path = `projects/${this.projectId}/uploads/${fileUUID}_${file.name}`;
    // const isComplete = false;

    // Define a empty one here so that there is a ref in onUploadEnd
    let removeEventListeners = () => {};

    const onUploadError = (event) => {
      throw new Error(`Upload failed: ${event.type}`);
    };

    const onUploadStart = (_event) => {
      onProgress(0);
    };

    const onUploadProgress = (event) => {
      const amount = (event.loaded / event.total);
      onProgress(amount);
    };

    const onUploadEnd = (_event) => {
      // isComplete = true;
      // console.log(event);
      console.log(`Successfully uploaded file for ${file.name}`);
      onComplete(fileUUID);
      removeEventListeners();
    };

    (async () => { // Switch to async function style to simplify coding style
      try {
        const params = new URLSearchParams({ path });
        const res = await window.fetch(`${this.signerUrl}?${params}`, { method: 'GET' });
        // console.warn({ res });
        const uploadInfo = await res.json();
        // console.warn(uploadInfo);

        xhr.upload.addEventListener('loadstart', onUploadStart);
        xhr.upload.addEventListener('progress', onUploadProgress);
        xhr.upload.addEventListener('loadend', onUploadEnd);
        xhr.upload.addEventListener('error', onUploadError);
        xhr.upload.addEventListener('timeout', onUploadError);
        // xhr.upload.addEventListener('abort', onError);

        removeEventListeners = () => {
          xhr.upload.removeEventListener('loadstart', onUploadStart);
          xhr.upload.removeEventListener('progress', onUploadProgress);
          xhr.upload.removeEventListener('loadend', onUploadEnd);
          xhr.upload.removeEventListener('error', onUploadError);
          xhr.upload.removeEventListener('timeout', onUploadError);
          // xhr.upload.removeEventListener('abort', onError);
        };

        xhr.open('PUT', uploadInfo.signedUrl, true);
        xhr.send(file);
      } catch (err) {
        xhr.abort();
        removeEventListeners();
        console.error(err);
      }
    })();

    return () => {
      xhr.abort();
      removeEventListeners();
    };
  }
}


/* S3.prototype.addMultiFileDialog = function(id){
  var $input = $("#"+id);
  var $text = $input.next('label').find("span");
  var originalText = $text.text();

  $input.change(e => {
    var files = e.target.files;

    //Show filename or number of files
    var fileName = "";
    if(files && files.length>1){
      fileName = "" + files.length + " files selected";
    } else if(e.target.value){
      fileName = e.target.value.split("\\").pop();
    }
    if(fileName){
      $text.text(fileName);
    } else {
      $text.text(originalText);
    }

    //Upload
    for(const file of files){
      this.evaporate.add({
          name: uuid(),
          file: file,
          complete: function(){
              console.log("Complete!");
          },
          progress: function(progress){
              console.log('Uploading: ' + (progress*100).toFixed(2) + "%...");
          }
        });
    };
  });
}; */
