import $ from 'jquery';
import Backbone from 'backbone';
import sideBarTemplate from 'templates/sidebar.handlebars';

const SideBarView = Backbone.View.extend({
  tagName: 'aside',
  className: 'main-sidebar',
  template: sideBarTemplate,
  initialize(options) {
    this.projectsData = options.projectsData;
    this.selectedProject = options.projectId;
  },
  sidebarShown: false,
  toggleSidebar() {
    if (this.sidebarShown) {
      this.$el[0].style = '';
      this.sidebarShown = false;
    } else {
      this.$el[0].style = 'transform: none';
      this.sidebarShown = true;
    }
  },
  render() {
    const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
    const sortedProjectsData = this.projectsData.toSorted((a, b) => collator.compare(a.name, b.name));
    const projectsWithSelected = sortedProjectsData.map(({ id, name }, i) => ({
      id,
      name,
      selected: id === this.selectedProject,
      first: i === 0, // hack to open first tree menu
    }));
    this.$el.html(this.template({ projects: projectsWithSelected }));
    $('.sidebar-toggle').on('click', this.toggleSidebar.bind(this));
    return this;
  },
});

export default SideBarView;
