import Backbone from 'backbone';
import headerTemplate from 'templates/header.handlebars';
import logo from '../img/idealayer-cms-logo.svg';

const HeaderView = Backbone.View.extend({
  tagName: 'header',
  className: 'main-header',
  template: headerTemplate,
  events: {
    'click #logout-button': 'handleClickLogout',
  },
  initialize(options) {
    this.envName = options.envName;
    this.showEnvName = options.showEnvName;
    this.theme = options.theme;
    this.userData = options.userData;
    this.onClickLogout = options.onClickLogout;
  },
  handleClickLogout(e) {
    e.preventDefault();
    this.onClickLogout();
  },
  render() {
    const { envName, showEnvName, theme } = this;
    const { name: userDisplayName, username: userEmail } = this.userData;
    this.$el.html(this.template({
      envName, showEnvName, logo, theme, userDisplayName, userEmail,
    }));
    return this;
  },
});

export default HeaderView;
