var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " style=\"background-color: "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"theme") : depth0)) != null ? lookupProperty(stack1,"colour1") : stack1), depth0))
    + ";\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span style=\"display: inline-block; color: white; padding: 10px\">("
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"envName") || (depth0 != null ? lookupProperty(depth0,"envName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"envName","hash":{},"data":data,"loc":{"start":{"line":6,"column":87},"end":{"line":6,"column":98}}}) : helper)))
    + " env)</span>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"#\" class=\"logo\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"theme") : depth0)) != null ? lookupProperty(stack1,"colour1") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":24},"end":{"line":1,"column":97}}})) != null ? stack1 : "")
    + ">\n  <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"logo") || (depth0 != null ? lookupProperty(depth0,"logo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"logo","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":20}}}) : helper)))
    + "\" style=\"vertical-align: baseline; height: 50px; object-fit: contain; padding: 10px;\"></img>\n</a>\n\n<nav class=\"navbar navbar-static-top\" role=\"navigation\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"theme") : depth0)) != null ? lookupProperty(stack1,"colour1") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":55},"end":{"line":5,"column":128}}})) != null ? stack1 : "")
    + ">\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showEnvName") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":6,"column":117}}})) != null ? stack1 : "")
    + "\n  <a href=\"#\" class=\"sidebar-toggle visible-xs-block\" data-toggle=\"offcanvas\" role=\"button\">\n    <span class=\"sr-only\">Toggle navigation</span>\n  </a>\n  <div class=\"navbar-custom-menu\">\n    <ul class=\"nav navbar-nav\">\n      <span style=\"color: #ddd\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"userDisplayName") || (depth0 != null ? lookupProperty(depth0,"userDisplayName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userDisplayName","hash":{},"data":data,"loc":{"start":{"line":12,"column":32},"end":{"line":12,"column":51}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"userEmail") || (depth0 != null ? lookupProperty(depth0,"userEmail") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userEmail","hash":{},"data":data,"loc":{"start":{"line":12,"column":53},"end":{"line":12,"column":66}}}) : helper)))
    + ")</span>\n      <a id=\"logout-button\" href=\"#\" style=\"display: inline-block; color: white; padding: 15px\"><i class=\"fa fa-fw fa-sign-out\"></i>Sign out</a>\n      <!--\n      <li class=\"dropdown user user-menu\">\n        <a href=\"#\" class=\"dropdown-toggle\" data-toggle=\"dropdown\">\n          <img src=\"img/aaron-160x160.jpg\" class=\"user-image\" alt=\"User Image\">\n          <span class=\"hidden-xs\">Aaron Cowie</span>\n        </a>\n        <ul class=\"dropdown-menu\">\n          <li class=\"user-header\">\n            <img src=\"img/aaron-160x160.jpg\" class=\"img-circle\" alt=\"User Image\">\n            <p>\n              Aaron Cowie - Senior Software Developer\n            </p>\n          </li>\n          <li class=\"user-footer\">\n            <div class=\"pull-left\">\n              <a href=\"#\" class=\"btn btn-default btn-flat\">Profile</a>\n            </div>\n            <div class=\"pull-right\">\n              <a href=\"#\" class=\"btn btn-default btn-flat\">Sign out</a>\n            </div>\n          </li>\n        </ul>\n      </li>-->\n    </ul>\n  </div>\n</nav>\n";
},"useData":true});