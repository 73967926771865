import $ from 'jquery';
import Backbone from 'backbone';
import noProjectsTemplate from 'templates/no-projects-page.handlebars';

const NoProjectsPageView = Backbone.View.extend({
  tagName: 'section',
  className: 'content-header',
  template: noProjectsTemplate,
  events: {

  },
  initialize(options) {
    
  },
  render() {
    this.$el.html(this.template({}));
    return this;
  },
});

export default NoProjectsPageView;
