import { DataModel, loadContent } from '@idealayer/cms-content';

import S3UploadHandler from './upload/s3';
import WebUploadHandler from './upload/web';

export default class Project {
  constructor(projectId, projectInfo, dataModelJSON, contentJSON, classLoader) {
    this.classLoader = classLoader;
    this.dataModel = new DataModel(this.classLoader, dataModelJSON);
    // console.log("DataModel: ", this.dataModel);
    this.content = loadContent(this.dataModel, this.classLoader, contentJSON);
    // console.log("Content: ", this.content);
    if (projectInfo.uploadDataStore.type === 's3') {
      this.uploadHandler = new S3UploadHandler(projectInfo.uploadDataStore, projectId);
    } else {
      this.uploadHandler = new WebUploadHandler(projectInfo.uploadDataStore, projectId);
    }
  }
}
