import * as Sentry from "@sentry/browser";

import "@babel/polyfill";
import $ from 'jquery';
import Backbone from 'backbone';

import Project from './project';

Sentry.init({
  dsn: 'https://6c06ce8255fa4faa94b295055ed44479@o34536.ingest.sentry.io/76465',
  integrations: [],
  release: '0.1.0',
  environment: process.env.NODE_ENV,
});

function notifyUser() {
  if (typeof $ === 'undefined') {
    alert('Something went wrong, the IdeaLayer support team have been notified.');
  } else {
    $('#errordialog').modal({
      backdrop: 'static',
      keyboard: false,
    });
  }
}

const chainedErrorHandler = (original) => (...args) => {
  if (original) original(...args);
  notifyUser();
};


window.onerror = chainedErrorHandler(window.onerror);
window.onunhandledrejection = chainedErrorHandler(
  window.onunhandledrejection
);



document.body.style = '';

import { renderSpaLoginPage } from './spaLoginPage';



import is from 'is';
import fetchJson from 'utils/fetchJson';
import FrontendClassLoader from './utils/frontendclassloader';

import Router from 'router';
import AppView from 'views/app';
import DataModelView from 'views/datamodel';
import ContentPageView from 'views/contentpage';

import { detect } from 'detect-browser';
import { getPromiseObject } from "./utils/getPromiseObject";
import NoProjectsPageView from "./views/noprojectspage";
import HeaderView from "./views/header";
import SideBarView from "./views/sidebar";

// handle the case where we don't detect the browser
const browser = detect();
switch (browser && browser.name) {
  case 'ie':
    alert('Internet Explorer is not supported. Please use Chrome, Firefox or Edge instead.');
    throw new Error('Internet Explorer is not supported. Please use Chrome, Firefox or Edge instead.');
    break;
}

const logout = async (webRoot) => {
  const response = await fetch(`${webRoot}/logout`, {
    method: 'POST',
    credentials: 'same-origin',
    redirect: 'error',
  });

  if (response.status !== 200) {
    throw new Error(`Looks like there was a problem. Status Code: ${response.status}`);
    // `Server error when fetching JSON from ${path }. Error: ${JSON.stringify(data, null, 2)}`
  }
};

// Wrap login SPA renderer in a promise
const showLoginPage = async (wrapperElement, envName, showEnvName, webRoot, theme) => {
  let unmountLoginPage;

  const { promise, resolve } = getPromiseObject();

  const onLoginSuccess = () => {
    unmountLoginPage();
    resolve();
  };

  unmountLoginPage = await renderSpaLoginPage(wrapperElement, envName, showEnvName, webRoot, theme, onLoginSuccess);

  await promise;
};

const wrapperElement = document.querySelector('.wrapper');

const handleEnvironmentDataChanged = (environmentData) => {
  const { envName, showEnvName } = environmentData;
  document.title = showEnvName ? `[${envName}] IdeaLayer CMS` : 'IdeaLayer CMS';
};

// Note that this is almost a copy-paste of App::reload() function below, make sure to keep them in sync
const preload = async () => {
  // We don't yet know the webRoot so can't use it here.
  // This is currently ok since we use hash urls for frontend navigation.
  // This won't work if we switch to client side routing though.
  const [environmentData, clientData] = await Promise.all([
    fetchJson('environment'),
    fetchJson('api/client', [200, 401]),
  ]);

  handleEnvironmentDataChanged(environmentData);

  const notLoggedIn = 'type' in clientData && clientData.type === 'error';
  if (notLoggedIn) {
    const { envName, showEnvName, webRoot, theme } = environmentData;
    await showLoginPage(wrapperElement, envName, showEnvName, webRoot, theme);
    // Update client data since it returned 401 last time
    // Do not treat 401 as success here since we have already successfully signed in
    const newClientData = await fetchJson(`${webRoot}/api/client`);
    return { environmentData, clientData: newClientData };
  }

  return { environmentData, clientData };
};

class App {
  constructor(preloadData) {
    this.environmentData = preloadData.environmentData;
    this.clientData = preloadData.clientData;

    this.wrapperElement = document.querySelector('.wrapper');

    this.appView = null;
    this.headerView = null;
    this.sideBarView = null;
    this.currentPageView = null;

    this.router = null;
  
    this.inactivityTask = undefined;
    this.setupIdleTimeout();

    this.classLoader = new FrontendClassLoader();
    console.log('Content Types Loaded.');

    this.currentProjectId = null;

    this.renderAppView();
    this.router = new Router({ app: this });
    Backbone.history.start(/* { pushState: true} */);
  }

  // Note that this is almost a copy-paste of the preload() function at the top of this file, make sure to keep them in sync
  async reload() {
    // Use the webRoot this time since we have a previous successful environmentData response
    const { webRoot } = this.environmentData;

    const [environmentData, clientData] = await Promise.all([
      fetchJson(`${webRoot}/environment`),
      fetchJson(`${webRoot}/api/client`, [200, 401]),
    ]);
    this.environmentData = environmentData;
    this.clientData = clientData;

    handleEnvironmentDataChanged(environmentData);
  
    const notLoggedIn = 'type' in clientData && clientData.type === 'error';
    if (notLoggedIn) {
      // Avoid complexity of clearing out signed in in-memory state by reloading the page
      window.navigator.reload();
    }
  }

  async loadProject(projectId) {
    const { webRoot } = this.environmentData;

    const [projectInfoJSON, dataModelJSON, contentJSONResponse] = await Promise.all([
      fetchJson(`${webRoot}/api/projects/${projectId}/info`),
      fetchJson(`${webRoot}/api/projects/${projectId}/datamodel`),
      fetchJson(`${webRoot}/api/projects/${projectId}/content`),
    ]);
    
    const { revision: contentRevision, data: contentJSON } = contentJSONResponse;

    this.contentRevision = contentRevision;

    if (!is.object(dataModelJSON.main)) {
      console.error('main missing from data model');
      return;
    }

    this.project = new Project(projectId, projectInfoJSON, dataModelJSON, contentJSON, this.classLoader);
  }

  // mainly just updates the selected project in the side nav
  renderAppView() {
    const { envName, showEnvName, webRoot, theme } = this.environmentData;
    const { user: userData, projects: projectsData } = this.clientData;

    if (this.appView) this.appView.remove();
    this.appView = new AppView({ });
    this.appView.render();
    $(this.wrapperElement).html(this.appView.$el);

    if (this.headerView) this.headerView.remove();
    
    this.headerView = new HeaderView({
      envName,
      showEnvName,
      theme,
      userData,
      onClickLogout: async () => {
        await logout(webRoot);
        window.location = `${webRoot}/`;
      },
    });
    this.headerView.render();
    this.appView.$el.find('.main-header').html(this.headerView.$el);

    if (this.sideBarView) this.sideBarView.remove();
    this.sideBarView = new SideBarView({
      projectsData,
      projectId: this.currentProjectId,
    });
    this.sideBarView.render();
    this.appView.$el.find('.main-sidebar').html(this.sideBarView.$el);
  }

  resetTimer() {
    clearTimeout(this.inactivityTask);
    this.inactivityTask = setTimeout(this.refresh, 10 * 60 * 1000);
  }

  disableTimer() {
    clearTimeout(this.inactivityTask);
  }

  enableTimer() {
    clearTimeout(this.inactivityTask);
    this.inactivityTask = setTimeout(this.refresh, 10 * 60 * 1000);
  }
  
  // WORKAROUND/HACK (from https://stackoverflow.com/questions/667555/detecting-idle-time-in-javascript-elegantly)
  // Force reload page if idle (to prevent leaving window open and then trampling someone else's changes)
  setupIdleTimeout() {
    this.resetTimer();
    var events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    const _this = this;
    events.forEach(function(name) {
      document.addEventListener(name, _this.resetTimer, true); 
    });
  }

  //From https://stackoverflow.com/questions/1144805/scroll-to-the-top-of-the-page-using-javascript-jquery
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  getDefaultProject() {
    const { projects: projectsData } = this.clientData;
    const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
    const sortedProjects = projectsData.toSorted((a, b) => collator.compare(a.name, b.name));
    return sortedProjects[0];
  }

  getCurrentProject() {
    const { projects: projectsData } = this.clientData;
    return projectsData.find(({ id }) => id === this.currentProjectId);
  }

  async changeProject(projectId) {
    this.currentProjectId = projectId;
    await this.loadProject(projectId);
    this.renderAppView();
  }

  requestPageChange() {
    if (this.currentPageView && this.currentPageView.requestPageChange) {
      return this.currentPageView.requestPageChange();
    }
    return Promise.resolve(true);
  }

  changePage(view) {
    this.scrollToTop();
    
    if (this.currentPageView !== null) {
      this.currentPageView.remove();
      this.currentPageView = null;
    }
    this.currentPageView = view;
  }

  async changeToNoProjectsPage() {
    const noProjectsPageView = new NoProjectsPageView();
    this.changePage(noProjectsPageView);
    $('.content-wrapper').html(noProjectsPageView.render().el);
  }

  async tryChangeToContentPage(projectId, contentPath) {
    const allowed = await this.requestPageChange();
    if (allowed) {
      Backbone.history.navigate(`/projects/${projectId}/content/${contentPath}`, { trigger: true, replace: true });
      // await this.changeToContentPage(projectId, contentPath);
    }
    // else cancel was pressed, do nothing
  }

  renderContentPage(contentPath) {
    const contentPageView = new ContentPageView({
      content: this.project.content,
      path: contentPath,
      app: this,
      classLoader: this.classLoader,
      project: this.getCurrentProject(),
    });
    this.changePage(contentPageView);
    $('.content-wrapper').html(contentPageView.render().el);
  }

  async changeToContentPage(projectId, contentPath) {
    if (projectId !== this.currentProjectId) {
      await this.changeProject(projectId);
    }
    this.renderContentPage(contentPath);
  }

  async reloadContentPage(contentPath) {
    // await this.reload();
    await this.loadProject(this.currentProjectId);
    this.renderAppView();
    this.renderContentPage(contentPath);
    // window.location.reload();
  }

  renderDataModelPage() {
    const dataModelView = new DataModelView({
      app: this,
      classLoader: this.classLoader,
      dataModel: this.dataModel,
      project: this.getCurrentProject(),
    });
    this.changePage(dataModelView);
    $('.content-wrapper').html(dataModelView.render().el);
  }

  async changeToDataModelPage(projectId) {
    if (projectId !== this.currentProjectId) {
      await this.changeProject(projectId);
    }

    this.renderDataModelPage();
  }
}

(async () => {
  const preloadData = await preload();
  window.app = new App(preloadData);
})();

