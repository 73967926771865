// eslint-disable-next-line import/prefer-default-export
export const getPromiseObject = () => {
  let resolvePromise;
  let rejectPromise;
  const promise = new Promise((res, rej) => {
    resolvePromise = res;
    rejectPromise = rej;
  });
  let isSettled = false;
  return {
    resolve: (value) => {
      if (isSettled) {
        throw new Error('Promise is already settled. Cannot resolve.');
      }
      resolvePromise(value);
      isSettled = true;
    },
    reject: (err) => {
      if (isSettled) {
        throw new Error('Promise is already settled. Cannot reject.');
      }
      rejectPromise(err);
      isSettled = true;
    },
    getIsSettled: () => isSettled,
    promise,
  };
};
