import { ContentClassLoader, ClassLoader} from '@idealayer/cms-content';

import textView from '../views/content/text';
import longTextView from '../views/content/long_text';
import fileView from '../views/content/file';
import objectView from '../views/content/object';
import listView from '../views/content/list';
import dollarsView from '../views/content/dollars';
import linkView from '../views/content/link';
import selectOneView from '../views/content/select';
import urlView from '../views/content/url';
import booleanView from '../views/content/boolean';
import htmlView from '../views/content/html';
import modelView from '../views/content/model';

const { addClass, getClass } = ClassLoader;
export default class FrontendClassLoader extends ContentClassLoader {
  views = {};

  constructor() {
    super();
    this.addView = addClass.bind(this, 'View', this.views);
    this.getView = getClass.bind(this, 'View', this.views);
    this.loadDefaultContentTypes();
  }

  loadDefaultContentTypes = () => {
    this.addView(textView.name, textView.view);
    this.addView(longTextView.name, longTextView.view);
    this.addView(fileView.name, fileView.view);
    this.addView(objectView.name, objectView.view);
    this.addView(listView.name, listView.view);
    this.addView(dollarsView.name, dollarsView.view);
    this.addView(linkView.name, linkView.view);
    this.addView(selectOneView.name, selectOneView.view);
    this.addView(urlView.name, urlView.view);
    this.addView(booleanView.name, booleanView.view);
    this.addView(htmlView.name, htmlView.view);
    this.addView(modelView.name, modelView.view);
  }
}