import $ from 'jquery';

export default class WebUploadHandler {
  constructor(uploadDataStoreInfo, projectId) {
    this.projectId = projectId;
    this.uploadURL = uploadDataStoreInfo.uploadURL;
  }

  getDownloadURL(contentSetFilePath) {
    return `api/projects/${this.projectId}/download/${contentSetFilePath}`;
  }

  doUpload(file, onComplete, onProgress) {
    let isComplete = false;

    const formData = new FormData();
    formData.append('userFile', file, file.name);
    let currentUpload = $.ajax({
      type: 'POST',
      url: `api/projects/${this.projectId}${this.uploadURL}`,
      data: formData,
      enctype: 'multipart/form-data',
      contentType: false,
      processData: false,
      success(data) {
        isComplete = true;
        currentUpload = null;
        console.log(data);
        onComplete(data.fileUUID);
      },
      error(data) {
        console.error(data);
      },
      /* progress: function(progress){
                if(!isComplete) onProgress(progress);
                } */
    });
    return () => {
      currentUpload.abort();
    };
  }
}
