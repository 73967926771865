import logoUrl from './img/idealayer-cms-logo.svg';

const loginLogoCss = /* css */`
.login-logo {
  display: flex;
  justify-content: center;

  margin-bottom: 0px;
  background-color: #263238;
  color: #fff;
  font-size: 28px;
  height: 48px;
}
.login-logo-image {
  height: 48px;
  object-fit: contain;
  padding: 10px;
}
.login-logo-env-label {
  color: white;
  padding: 10px;
  font-size: 16px;
}
`;

const renderLoginLogoCss = () => {
  const style = document.createElement('style');
  style.textContent = loginLogoCss;
  document.head.appendChild(style);
  return () => {
    style.parentNode.removeChild(style);
  };
};

const defaultLoginLogoOuterHtml = /* html */ `
  <div id="login-logo" class="login-logo" style="border-top-left-radius: 5px; border-top-right-radius: 5px;"></div>
`;

const renderLoginLogo = (envName, showEnvName, theme) => {
  const unmountLoginLogoCss = renderLoginLogoCss();
  const envNameStr = showEnvName ? /* html */ `<span class="login-logo-env-label">(${envName} env)</span>` : '';
  const { colour1/* , colour2 */ } = theme;
  const outerHtml = /* html */ `
<div id="login-logo" class="login-logo"${colour1 ? ` style="background-color: ${colour1}"` : ''} style="border-top-left-radius: 5px; border-top-right-radius: 5px;">
  <img class="login-logo-image" height='33px' src='${logoUrl}'/>${envNameStr}
</div>
`;
  // Can't reuse the reference since outerHTML replaces the element
  document.getElementById('login-logo').outerHTML = outerHtml;
  return () => {
    // Can't reuse the reference since outerHTML replaces the element
    document.getElementById('login-logo').outerHTML = defaultLoginLogoOuterHtml;
    unmountLoginLogoCss();
  };
};

const defaultMessagesOuterHtml = '<div id="messages"></div>';

const renderMessages = (messages) => {
  if (messages.length === 0) {
    // Can't reuse the reference since outerHTML replaces the element
    document.getElementById('messages').outerHTML = defaultMessagesOuterHtml;
    return () => {
      // Do nothing
    };
  }

  const messageElements = messages.map((message) => {
    const p = document.createElement('p');
    p.className = 'login-box-msg';
    p.textContent = message;
    p.style = 'color: red;';
    return p;
  });

  const messagesElement = document.createElement('div');
  messagesElement.id = 'messages';
  messagesElement.replaceChildren(...messageElements);

  // Can't reuse the reference since outerHTML replaces the element
  document.getElementById('messages').replaceWith(messagesElement);
  return () => {
    // Can't reuse the reference since outerHTML replaces the element
    document.getElementById('messages').outerHTML = defaultMessagesOuterHtml;
  };
};

const loginFormOuterHtml = /* html */`
<form id="login-form" style="margin-bottom: 20px">
  <div
    class="form-group"
    style="width: 17em; margin-left: auto; margin-right: auto"
  >
    <input
      type="text"
      name="username"
      class="form-control"
      placeholder="Username"
      style="min-width: 17em; margin-bottom: 5px; border-radius: 5px"
    />
    <input
      type="password"
      name="password"
      class="form-control"
      placeholder="Password"
      style="min-width: 17em; border-radius: 5px"
    />
    <!--<span class="glyphicon glyphicon-lock form-control-feedback"></span>-->
  </div>
  <div
    class="form-group"
    style="width: 145px; margin-left: auto; margin-right: auto"
  >
    <button
      type="submit"
      value="Log In"
      class="btn btn-primary btn-block"
      style="width: 145px; margin-left: auto; margin-right: auto"
    >
      Sign In
    </button>
  </div>
</form>
`;

const loginBoxOuterHtml = /* html */`
<div class="login-box" style="min-width: 30em">
  ${defaultLoginLogoOuterHtml}
  <div class="login-box-body">
    <p class="login-box-msg">Please enter your username and password.</p>
    ${defaultMessagesOuterHtml}
    ${loginFormOuterHtml}
  </div>
</div>`;

const login = async (webRoot, loginData) => {
  const response = await fetch(`${webRoot}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(loginData),
    redirect: 'error',
  });
  const value = await response.json();
  return value;
};

const renderLoginBox = (wrapperElement, envName, showEnvName, webRoot, theme, onLoginSuccess) => {
  wrapperElement.innerHTML = loginBoxOuterHtml;

  const unmountLoginLogo = renderLoginLogo(envName, showEnvName, theme);
  let unmountMessages = renderMessages([]);

  const loginFormElement = document.getElementById('login-form');
  
  const onSubmit = async (e) => { // On rare occasions, onSubmit() is called more than once
    e.preventDefault();
    const formData = new FormData(loginFormElement);
    const loginData = Object.fromEntries(formData.entries());
    const value = await login(webRoot, loginData);
    if (value.status === 'error') {
      unmountMessages();
      unmountMessages = renderMessages([value.message]);
    } else {
      onLoginSuccess();
    }
  };

  loginFormElement.addEventListener('submit', onSubmit);
  return () => {
    loginFormElement.removeEventListener('submit', onSubmit);
    unmountLoginLogo();
    unmountMessages();
    wrapperElement.innerHTML = '';
  };
};

// eslint-disable-next-line import/prefer-default-export
export async function renderSpaLoginPage(wrapperElement, envName, showEnvName, webRoot, theme, onLoginSuccess) {
  return renderLoginBox(wrapperElement, envName, showEnvName, webRoot, theme, onLoginSuccess);
}
