import $ from 'jquery';
import viewTemplate from 'templates/content/object_view.handlebars';
import editTemplate from 'templates/content/object_edit.handlebars';
import itemViewTemplate from 'templates/content/object_item_view.handlebars';
import itemEditTemplate from 'templates/content/object_item_edit.handlebars';
import ContentView from '../content';

// Backbone view
const ObjectContentView = ContentView.extend({
  events: {
    'click .preview-nav': 'onClickPreviewNav',
  },
  initialize(options) {
    ContentView.prototype.initialize.call(this, {
      app: options.app, content: options.content, parent: options.parent, viewTemplate: '', editTemplate: '', previewTemplate: null, pageCallbacks: options.pageCallbacks
    });
    this.typename = 'ObjectContentView';
    this.classLoader = this.content.contentClassLoader;

    this.childViews = [];
    this.childViewsLoaded = false;
    // this.itemViewTemplate = '<div class="form-group"><label for="{{id}}" class="col-xs-2 control-label">{{name}}</label><div class="col-xs-10 placecontenthere"></div></div>';
    this.itemViewTemplate = itemViewTemplate;
    this.itemEditTemplate = itemEditTemplate;
    this.shouldPreview = !!this.parent; // || config.shouldPreview?
    this.setEditMode(false);
  },
  setEditMode(edit) { // propagate down to children if loaded
    ContentView.prototype.setEditMode.call(this, edit);
    if (!this.shouldPreview) {
      for (const { view } of this.childViews) {
        view.setEditMode(edit);
      }
    }
  },
  loadChildViews() {
    if (this.childViews.length > 0) {
      throw new Error('Child views already loaded.');
    }

    if (this.shouldPreview) {
      return;
    }

    /*if (this.childViews.length > 0) {
      for (const obj of this.childViews) {
        obj.view.remove();
      }
      this.childViews = [];
    }*/
    
    for (const [modelAttributeName, childContent] of this.content.modelAttributeNameToChildContent.entries()) {
      var viewClass = this.classLoader.getView(childContent.name);
      var view = new viewClass({ app: this.app, content: childContent, parent: this, pageCallbacks: this.pageCallbacks });
      this.childViews.push({ key: modelAttributeName, view: view });
    };

    for (const {view} of this.childViews) {
      if (view.loadChildViews !== undefined) {
        view.loadChildViews();
      }
    }

    this.childViewsLoaded = true;
  },
  clear() {
    ContentView.prototype.clear.call(this);
    this.childViews = [];
    this.childViewsLoaded = false;
    this.loadChildViews();
  },
  renderPreview() {
    this.$el.removeClass('contenttype-object');
    this.$el.addClass('contenttype-object-preview');

    this.$el.attr('data-objectid', this.content.uuid);
    this.$el.attr('data-modelname', this.content.contentType.model.name);
    
    this.$el.html(`<a class='preview-nav btn' style='font-size: 1.25rem; color: #3c8dbc; border-color: #3c8dbc; background-color: white;' href>${this.content.getDisplayName()} <i class="fa fa-fw fa-angle-right" style="width: 1em;"></i></a></div>`);
  },
  renderView() {
    this.$el.removeClass('contenttype-object-preview');
    this.$el.addClass('contenttype-object');

    if (!this.childViewsLoaded)
      throw new Error('loadChildViews must be called before renderView()');

    if (this.childViews.length === 0) {
      this.$el.html('<div class="form-control-static adjustpadding">None</div>');
      return;
    }

    this.renderInner();
  },
  renderEdit() {
    this.$el.removeClass('contenttype-object-preview');
    this.$el.addClass('contenttype-object');
    if (!this.childViewsLoaded) throw new Error('loadChildViews must be called before renderEdit()');
    if (this.childViews.length === 0) {
      this.$el.html('<div class="form-control-static adjustpadding">None</div>');
      return;
    }
    this.renderInner();
  },
  renderInner() {
    const content = document.createDocumentFragment();
    for (const obj of this.childViews) {
      const view = obj.view;
      var $inner;
      if (this.mode === 'edit') {
        $inner = $(this.itemEditTemplate({
          id: view.getDOMId(), name: view.content.contentType.displayName || '', description: view.content.contentType.description, hint: view.content.contentType.hint, hidden: view.content.contentType.hidden,
        }));
      } else {
        $inner = $(this.itemViewTemplate({
          id: view.getDOMId(), name: view.content.contentType.displayName || '', description: view.content.contentType.description, contentPath: view.content.getContentPath(), hidden: view.content.contentType.hidden,
        }));
      }
      const $slot = $inner.find('.placecontenthere').removeClass('placecontenthere');
      $slot.html(view.render().el);
      content.appendChild($inner[0]);
    }
    this.$el.html(content);
  },
  onClickPreviewNav(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.pageCallbacks && this.pageCallbacks.shouldAllowSelection && this.pageCallbacks.onSelect && this.pageCallbacks.shouldAllowSelection(this)) { 
      this.pageCallbacks.onSelect(this);
      return;
    }

    if (this.pageCallbacks && this.pageCallbacks.onNavigate) {
      const contentPath = this.content.getContentPath();
      this.pageCallbacks.onNavigate(contentPath);
    }
  },
  getChangedViews() {
    let changedViews = ContentView.prototype.getChangedViews.call(this);
    for (const {view} of this.childViews) {
      const childViews = view.getChangedViews();
      changedViews = [...changedViews, ...childViews];
    }
    return changedViews;
  },
});

// Another hack, this time for properly extending events
// https://danhough.com/blog/backbone-view-inheritance/
ObjectContentView.extend = function (child) {
  const view = ContentView.extend.apply(this, arguments);
  view.prototype.events = Object.assign({}, this.prototype.events, child.events);
  return view;
};

ContentView.extend(ObjectContentView);

export default {
  name: 'object',
  view: ObjectContentView,
};
